import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Create = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [author, setAuthor] = useState('Myles');
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) =>{
       e.preventDefault();
       const blog = {title, body, author};

       setIsPending(true);

       fetch('http://localhost:8000/blogs', {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(blog)
       }).then(() => {
         console.log('new blog added');
         setIsPending(false);
         navigate('/');
       })
         
       
  }

  return (
    <div className='create'>
        <h2>Add a new Content</h2>
        <form onSubmit={handleSubmit}>
          <label>Blog Title:</label>
          <input 
            type="text"
            required
            value={title}
            onChange={(e)=> setTitle(e.target.value)}
          />
          <label>Blog Body:</label>
          <textarea
           required
           cols="30" 
           rows="10"
           value={body}
            onChange={(e)=> setBody(e.target.value)} 
          ></textarea>
          <label>Blog Author:</label>
          <select
           value={author}
           onChange={(e)=> setAuthor(e.target.value)}
          >
            <option value="Talia">Talia</option>
            <option value="Myles">Myles</option>
            <option value="Goldia">Goldia</option>
          </select>
          {!isPending && <button>Add Blog</button>}
          {isPending && <button disabled>Add Blog...</button>}
        </form>
      
    </div>
  )
}

export default Create
