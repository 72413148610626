import React from 'react';
import { Link } from "react-router-dom"

const Navbar = () => {
  return (
    <nav className='navbar'>
        <Link to="/" style={{ fontSize: 35, fontWeight: 700, color:'blue' }}>Samtabe</Link>
        <div className="links">
            <Link to='/'>Home</Link>
            <Link to='/create' style={{ 
          color: 'white', 
          backgroundColor: '#f1356d',
          borderRadius: '8px' 
        }}>New Blog</Link>
        </div>
    </nav>
  )
}

export default Navbar
